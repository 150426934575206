// extracted by mini-css-extract-plugin
export var root = "verdict-module--root--1iQRQ";
export var header = "verdict-module--header--1Jvdk";
export var text = "verdict-module--text--1Bkay";
export var issue = "verdict-module--issue--m-yz4";
export var openInNewIcon = "verdict-module--open-in-new-icon--3kWzu";
export var heading = "verdict-module--heading--1rx7e";
export var number = "verdict-module--number--22Dpg";
export var author = "verdict-module--author--1a13_";
export var detailedButton = "verdict-module--detailed-button--27pX8";
export var title = "verdict-module--title--1J_jU";
export var subtitle = "verdict-module--subtitle--2z2Ql";
export var buttonContainer = "verdict-module--button-container--2faty";